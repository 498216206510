import React from 'react';
import styled from 'styled-components';
import {navigate} from 'gatsby';
import {useOutlet} from 'reconnect.js';
import * as AppContext from '../AppContext';
import * as Widget from '../components/Widget';
import InfoCard from '../components/InfoCard';
import * as Ant from 'antd';

class ErrUser extends Error {}

function ResetPasswordPage(props) {
  const [user] = useOutlet('user');
  const [actions] = useOutlet('actions');
  const [orgPassword, setOrgPassword] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');

  const submit = async () => {
    actions.setLoading(true);

    try {
      if (!password || !confirmPassword || !orgPassword) {
        throw new ErrUser('不能有空白的欄位');
      }
      if (confirmPassword !== password) {
        throw new ErrUser('新密碼需和確認密碼一致');
      }
      await actions.resetPassword({
        org_password: orgPassword,
        new_password: password,
      });
      Ant.message.success('成功修改！請重新登入');

      Ant.message.loading('即將登出...', 1);
      actions.logout();
    } catch (err) {
      console.warn(err);
      if (err instanceof ErrUser) {
        Ant.message.warning(err.message);
      } else if (err.response && err.response.error) {
        Ant.message.error(
          {
            'incorrect org_password': '請輸入正確舊密碼',
          }[err.response.error] || '發生例外錯誤',
        );
      } else {
        Ant.message.error('發生錯誤');
      }
    }
    actions.setLoading(false);
  };

  if (!user) {
    return null;
  }

  return (
    <Wrapper>
      <Widget.MaxWidthCenter>
        <h2>Reset Password</h2>
      </Widget.MaxWidthCenter>

      <Widget.MaxWidthCenter margin={30}>
        <InfoCard>
          <h2>User Profile</h2>
          {user && (
            <Ant.Form onFinish={() => submit()}>
              <Widget.FlexRow style={{marginBottom: 20}}>
                <div style={{width: 120, color: '#ccc'}}>Username</div>
                <div>{user.username}</div>
              </Widget.FlexRow>
              <Widget.FlexRow style={{marginBottom: 20}}>
                <div style={{width: 120, color: '#ccc'}}>舊密碼</div>
                <Ant.Input.Password
                  value={orgPassword}
                  onChange={(e) => setOrgPassword(e.target.value)}
                  style={{width: 200}}
                />
              </Widget.FlexRow>
              <Widget.FlexRow style={{marginBottom: 20}}>
                <div style={{width: 120, color: '#ccc'}}>新密碼</div>
                <Ant.Input.Password
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  style={{width: 200}}
                />
              </Widget.FlexRow>
              <Widget.FlexRow style={{marginBottom: 20}}>
                <div style={{width: 120, color: '#ccc'}}>確認新密碼</div>
                <Ant.Input.Password
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  style={{width: 200}}
                />
              </Widget.FlexRow>

              <Widget.FlexRow style={{marginBottom: 20}}>
                <div style={{flex: 1}} />
                <Ant.Button type="primary" htmlType="submit">
                  修改
                </Ant.Button>
              </Widget.FlexRow>
            </Ant.Form>
          )}
        </InfoCard>
      </Widget.MaxWidthCenter>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 80px 20px;
  background-color: ${AppContext.Theme.lightBgColor};
  min-height: 100vh;
`;

export default ResetPasswordPage;
